var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticStyle: { "max-width": "600px" } },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("\n      Want a $3 discount?\n    ")]),
          _c("v-card-text", [
            _c("div", [
              _vm._v("Email "),
              _c("a", { attrs: { href: "mailto:support@truple.io" } }, [
                _vm._v("support@truple.io")
              ]),
              _vm._v(
                " a 150-500 word review describing why you use and/or benefit from using Truple/Best Accountability and grant us permission to post it publicy, anonymously, through social media or other marketing means, and we'll put a $3 coupon on your account."
              )
            ]),
            _c("br"),
            _c("div", [
              _c("span", { staticClass: "caption" }, [
                _vm._v(
                  "* Only once per customer.  Discount is $1 off / month for 3 months, limited to website based subscriptions only (no Google Play Subscriptions).  We reserve the right to reject your review for any reason.  Once the discount has been applied to your account the review is permanent and will not be removed."
                )
              ])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }