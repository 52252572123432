<template>
  <v-container style="max-width: 600px;">
    <v-card>
      <v-card-title>
        Want a $3 discount?
      </v-card-title>
      <v-card-text>
        <div>Email <a href="mailto:support@truple.io">support@truple.io</a> a 150-500 word review describing why you use and/or benefit from using Truple/Best Accountability and grant us permission to post it publicy, anonymously, through social media or other marketing means, and we'll put a $3 coupon on your account.</div>
        <br />
        <div><span class="caption">* Only once per customer.  Discount is $1 off / month for 3 months, limited to website based subscriptions only (no Google Play Subscriptions).  We reserve the right to reject your review for any reason.  Once the discount has been applied to your account the review is permanent and will not be removed.</span></div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: "Discounts.vue"
  }
</script>

<style scoped>

</style>